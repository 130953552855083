<template>
  <v-app-bar
    id="default-app-bar"
    app
    absolute
    class="v-bar--underline"
    color="white"
    :clipped-left="$vuetify.rtl"
    :clipped-right="!$vuetify.rtl"
    height="55"
    flat
  >
    <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer" />

    <default-drawer-toggle class="hidden-sm-and-down" />

    <v-spacer />
    <v-container>
      <v-row align="center">
        <v-col cols="12" md="12" class="text-center">
          <div class="body-1 font-weight-light pt-6 pt-md-0">
            <img
              src="/hazamlesz_logo_retina.png"
              class="on-demand-logo"
              style="height: 44px"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
// Utilities
import { get, sync } from "vuex-pathify";

export default {
  name: "DefaultBar",

  components: {
    DefaultAccount: () =>
      import(
        /* webpackChunkName: "default-account" */
        "./widgets/Account"
      ),
    DefaultDrawerToggle: () =>
      import(
        /* webpackChunkName: "default-drawer-toggle" */
        "./widgets/DrawerToggle"
      )
  },

  computed: {
    ...get("user", ["dark", "gradient", "image"]),
    ...sync("app", ["drawer", "mini"]),
    name: get("route/name")
  }
};
</script>
